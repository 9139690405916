<template>
  <div>
    <!-- begin:: Content Head -->
    <KTSubheader v-bind:title="'Calls'" />
    <!-- end:: Content Head -->
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-lg-12 col-xxl-12">
        <CallList></CallList>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import CallList from "../../content/calls/list/CallList.vue";

export default {
  name: "calls",
  components: {
    KTSubheader,
    CallList
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Calls" }]);
  }
};
</script>
